import Vue from "vue";
import axios from "axios";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router/main";
import Argon from "./plugins/argon-kit";
import store from "./store";
import "./vee-validate.js";
import Toasted from "vue-toasted";
import VueNumber from "vue-number-animation";

Vue.use(VueNumber);
Vue.prototype.$axios = axios.create({
  baseURL: store.getters.baseUrl
});

if (localStorage.getItem("access")) {
  Vue.prototype.$axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("access");
}

Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.use(Argon);
Vue.use(VueMeta);
Vue.use(Toasted, {
  router
});

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

export { vm };
