/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { vm } from "../main.js";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    auth: !!localStorage.getItem("access"),
    access: localStorage.getItem("access"),
    refresh: localStorage.getItem("refresh"),
    after_login: "/account",
    login_message: null,
    live_data: [],
    request_successful: false,
    live_data_notification: {},
    baseUrl: "http://5.161.241.37/api/",
    search_url: null,
    search_cache: {
      country_list: [],
      matches: null,
      query: null,
      reason: null,
      results: [],
      search_allocation: null,
      status: null,
      time: null,
      query_country_list: [],
      page_number: 1
    },
    search_history_cache: null,
    realtime_cache: null,
    records_cache: null,
    history_tab_id: 0,
    direct_search: {
      search: { country: "", fields: {} },
      fields: [],
      country_list_selected: null,
      // data: {},

      data: {},

      processing: false,
      error: null,
      interval: null,
      completed: false,
      results: [],
    },
    live_data_company: null
  },
  getters: {
    get_live_data_company() {
      return state.live_data_company;
    },
    current_tab(state) {
      return state.history_tab_id;
    },
    records_cache(state) {
      return state.records_cache;
    },
    search_history_cache(state) {
      return state.search_history_cache;
    },
    realtime_cache(state) {
      return state.realtime_cache;
    },
    auth(state) {
      return state.auth;
    },
    request_successful(state) {
      return state.request_successful;
    },
    access(state) {
      return state.access;
    },
    refresh(state) {
      return state.refresh;
    },
    after_login(state) {
      return state.after_login;
    },
    login_message(state) {
      return state.login_message;
    },
    live_data(state) {
      return state.live_data;
    },
    baseUrl(state) {
      return state.baseUrl;
    },
    search_url(state) {
      return state.search_url;
    },
    direct_search(state) {
      return state.direct_search;
    },
    direct_search_results(state) {
      return state.direct_search.results;
    },
    search_cache(state) {
      return state.search_cache;
    }
  },
  mutations: {
    set_live_data_company(state, data) {
      state.live_data_company = data;
    },
    set_history_tab_id(state, data) {
      state.history_tab_id = data;
    },
    set_search_page(state, data) {
      state.search_cache.page_number = data;
    },
    auth(state, val) {
      state.auth = val;
    },
    set_search_history_cache(state, data) {
      state.search_history_cache = data;
    },
    set_realtime_cache(state, data) {
      state.realtime_cache = data;
    },
    set_records_cache(state, data) {
      state.records_cache = data;
    },
    access(state, token) {
      state.access = token;
    },
    refresh(state, token) {
      state.refresh = token;
    },
    after_login(state, url) {
      state.after_login = url;
    },
    login_message(state, message) {
      state.login_message = message;
    },
    add_live_data(state, data) {
      state.live_data.unshift(data);
    },
    add_live_data_notification(state, urn) {
      state.live_data_notification[urn] = 0;
    },
    clear_live_data(state) {
      (state.live_data = []), (state.live_data_notification = {});
    },
    set_search_cache(state, data) {
      state.search_cache = data;
    },
    search_url(state, data) {
      state.search_url = data;
    },
    set_request_successful(state, data) {
      state.request_successful = data;
    },
    direct_search(state, data) {
      state.direct_search = data;
    },
    direct_search_key(state, { key, val }) {
      state.direct_search[key] = val;
    },
    cancel_direct_search(state) {
      clearInterval(state.direct_search.interval);
      state.direct_search.processing = false;
      state.direct_search.completed = false;
      state.direct_search.data = {};
    },
    clear_direct_search(state) {
      state.direct_search.results = [];
      state.direct_search.completed = false;
      state.direct_search.error = null;
    },
    update_live_data(state, { data }) {
      state.live_data.forEach((d, index) => {
        if (d.urid == data.urid) {
          state.live_data.splice(index, 1);
          state.live_data.splice(index, 0, data);
        }
      });
    },
  },
  actions: {
    clear_notification() {
      vm.$toasted.clear();
    },
    login({ commit }, { token, remember }) {
      commit("auth", true);
      commit("access", token.access);
      commit("refresh", token.refresh);
      vm.$axios.defaults.headers.common["Authorization"] =
        "Bearer " + token.access;
      if (remember) {
        // localStorage.setItem("auth", true);
        localStorage.setItem("access", token.access);
        localStorage.setItem("refresh", token.refresh);
      } else {
        // localStorage.removeItem("auth");
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
      }
    },
    show_notification(
      { context },
      { message, type = "success", position = "top-right", duration = 10000 }
    ) {
      vm.$toasted.show(message, {
        type: type,
        position: position,
        duration: duration,
        action: [
          {
            text: "x",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
    },
    logout(context) {
      context.commit("auth", false);
      // localStorage.removeItem("auth");
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      context.commit("clear_live_data");
      delete vm.$axios.defaults.headers.common["Authorization"];
      vm.$router.push("/login");
    },
    set_search_cache({state, commit}, data) {
      commit("set_search_cache", data);
    },
    async requestLiveData({ state, commit }, data) {
      commit("add_live_data", data);
      commit("add_live_data_notification", data.query);
      var interval = setInterval(() => {
        vm.$axios
          .get(`${state.baseUrl}search/realtime/status/?urid=${data.urid}`)
          .then((res) => {
            if (res.data) {
              commit("update_live_data", { data: res.data });

              if (res.data.response_status == "COMPLETED") {
                commit("set_request_successful", false);
                vm.$toasted.show(
                  `${
                    state.live_data_company ? state.live_data_company : ""
                  } <br> ${res.data.error_description}`,
                  {
                    type: "success",
                    position: "top-right",
                    duration: 30000,
                    action: [
                      {
                        text: "Detail",
                        onClick: (e, toastObject) => {
                          vm.$router.push(
                            `/search/detail/${res.data.query}-${res.data.country_code}?live=true`
                          );
                          toastObject.goAway(0);
                        },
                      },
                    ],
                  }
                );
                state.live_data_notification.push(res.data.query);
                clearInterval(interval);
              } else if (
                res.data.response_status == "REJECTED​" ||
                res.data.response_status == "ERROR"
              ) {
                vm.$toasted.show(
                  `${
                    state.live_data_company ? state.live_data_company : ""
                  } <br> Error, ${res.data.error_description}`,
                  {
                    type: "error",
                    position: "top-right",
                    duration: 30000,
                    action: [
                      {
                        text: "Detail",
                        onClick: (e, toastObject) => {
                          vm.$router.push(
                            `/search/detail/${res.data.query}-${res.data.country_code}`
                          );
                          toastObject.goAway(0);
                        },
                      },
                    ],
                  }
                );
                clearInterval(interval);
              } else {
                state.live_data_notification[res.data.query] += 1;

                var message = "";
                var notfs = state.live_data_notification[res.data.query];
                var notification_type = "toast_successful";
                if (notfs == 1) {
                  message = `We are still processing your request ${
                    state.live_data_company
                      ? "for " + state.live_data_company
                      : ""
                  }, this may take few minutes.
                  <br>You can continue to search and we will notify you when we have the
                  new data.`;
                }
                if (notfs == 3) {
                  message = `Your request ${
                    state.live_data_company
                      ? "for " + state.live_data_company
                      : ""
                  }, takes longer than we expected, <br>if you are not here we
                  will notify you via an email, when we have the new data.`;
                  notification_type = "toast_warning";
                }
                if (notfs == 1 || notfs == 3) {
                  vm.$toasted.show(`${message}`, {
                    type: notification_type,
                    position: "top-right",
                    duration: 20000,
                    action: {
                      text: "x",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      },
                    },
                  });
                }
              }
            }
          })
          .catch((err) => {
            if (err) {
              clearInterval(interval);
            }
          });
      }, 10000);
    },
    update_direct_search_records({ state }) {
      let country = state.direct_search.search.country.record_number;
      state.direct_search.data.latest_record.forEach((record) => {
        if (record && typeof record == "string") {
          vm.$axios
            .get(`search/detail/${record}-${country}/`)
            .then((res) => {
              state.direct_search.results.push(res.data);
            })
            .catch();
        }
      });
    },
    direct_search({ state, commit, dispatch }, data) {
      state.direct_search.data = data;
      state.direct_search.processing = true;
      commit("clear_direct_search");
      dispatch("show_notification", {
        message: "Request is processing to fetch data",
      });

      state.direct_search.interval = setInterval(() => {
        vm.$axios
          .get(
            `${state.baseUrl}search/realtime/status/?urid=${state.direct_search.data.urid}`
          )
          .then((res) => {
            state.direct_search.data = res.data;
            if (res.data.response_status == "COMPLETED") {
              if (!state.direct_search.completed) {
                state.direct_search.processing = false;
                state.direct_search.completed = true;
                clearInterval(state.direct_search.interval);
                dispatch("show_notification", {
                  message: res.data.error_description,
                });
                dispatch("update_direct_search_records");
              }
            } else if (
              res.data.response_status == "REJECTED​" ||
              res.data.response_status == "ERROR"
            ) {
              dispatch("show_notification", {
                message: res.data.error_description,
                type: "error",
              });
              commit("cancel_direct_search");
              state.direct_search.error = res.data.error_description;
            }
          })
          .catch((err) => {
            dispatch("show_notification", {
              message: "Something went wrong. Could not process search.",
              type: "error",
            });
            commit("cancel_direct_search");
            state.direct_search.error =
              "Something went wrong. Could not process search";
          });
      }, 5000);
    },
    set_request_successful({ state, commit }, data) {
      commit("set_request_successful", data);
    },
    cancel_direct_search({ commit, dispatch }) {
      commit("cancel_direct_search");
      dispatch("show_notification", {
        message: "Search cancelled",
        type: "info",
      });
    },
  },
  modules: {},
});
