import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { configure } from "vee-validate";
extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});
extend("email", {
  ...email,
  message: "Enter a valid email address"
});
extend("min", {
  ...min,
  message: "{_field_} must be at least {length} characters"
});
extend("terms", {
  validate: terms => {
    return terms == true;
  }
});
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});
extend("confirm_email", {
  params: ["target"],
  validate(value, { target }) {
    return value == target;
  },
  message: "Email confirmation does not match"
});

const config = {
  mode: "passive"
};

configure(config);
