import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

/*eslint-disable*/

const Header = () =>
  import(/* webpackChunkName: "Header" */ "../layout/AppHeader");
const Footer = () =>
  import(/* webpackChunkName: "Footer" */ "../layout/AppFooter");
const Home = () => import(/* webpackChunkName: "Home" */ ".././views/Home");
const Login = () => import(/* webpackChunkName: "Login" */ ".././views/Login");
const Signup = () =>
  import(/* webpackChunkName: "Signup" */ ".././views/Signup");
const Account = () =>
  import(/* webpackChunkName: "Account" */ "../views/Account");
const AboutUs = () =>
  import(/* webpackChunkName: "AboutUs" */ "../views/AboutUs");
  const SubscriptionPlans = () =>
  import(/* webpackChunkName: "SubscriptionPlans" */ "../views/SubscriptionPlans");
const Search = () => import(/* webpackChunkName: "Search" */ "../views/Search");
const ContactUs = () =>
  import(/* webpackChunkName: "ContactUs" */ "../views/ContactUs");
const FAQ = () => import(/* webpackChunkName: "FAQ" */ "../views/FAQ");
const BlogPosts = () =>
  import(/* webpackChunkName: "BlogPosts" */ "../views/BlogPosts");
const BlogPostDetail = () =>
  import(/* webpackChunkName: "BlogPostDetail" */ "../views/BlogPostDetail");
const Error404 = () =>
  import(/* webpackChunkName: "Error404" */ "../views/errors/404");
const Error500 = () =>
  import(/* webpackChunkName: "Error500" */ "../views/errors/500");
const SearchDetail = () =>
  import(/* webpackChunkName: "SearchDetail" */ "../views/SearchDetail");
const TermsAndConditions = () =>
  import(
    /* webpackChunkName: "TermsAndConditions" */ "../views/TermsAndConditions"
  );
const ForgotPassword = () =>
  import(/* webpackChunkName: "ForgotPassword" */ "../views/ForgotPassword");
const VerifyEmail = () =>
  import(/* webpackChunkName: "VerifyEmail" */ "../views/VerifyEmail");
const Live = () => import(/* webpackChunkName: "Live" */ "../views/Live");
const History = () =>
  import(/* webpackChunkName: "History" */ "../views/History");
const ResetPassword = () =>
  import(/* webpackChunkName: "ResetPassword" */ "../views/ResetPassword");
const DirectSearch = () =>
  import(/* webpackChunkName: "DirectSearch" */ "../views/DirectSearch");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      header: Header,
      default: Home,
      footer: Footer,
    },
  },
  {
    path: "/login",
    name: "Login",
    components: {
      header: Header,
      default: Login,
      footer: Footer,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    components: {
      header: Header,
      default: Signup,
      footer: Footer,
    },
  },
  {
    path: "/account",
    name: "Account",
    components: {
      header: Header,
      default: Account,
      footer: Footer,
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    components: {
      header: Header,
      default: AboutUs,
      footer: Footer,
    },
  },
  {
    path: "/subscription-Plans",
    name: "SubscriptionPlans",
    components: {
      header: Header,
      default: SubscriptionPlans,
      footer: Footer,
    },
  },
  {
    path: "/search",
    name: "Search",
    components: {
      header: Header,
      default: Search,
      footer: Footer,
    },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    components: {
      header: Header,
      default: ContactUs,
      footer: Footer,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    components: {
      header: Header,
      default: FAQ,
      footer: Footer,
    },
  },
  {
    path: "/blog",
    name: "BlogPosts",
    components: {
      header: Header,
      default: BlogPosts,
      footer: Footer,
    },
  },
  {
    path: "/blog/:id/:slug",
    name: "BlogPost",
    components: {
      header: Header,
      default: BlogPostDetail,
      footer: Footer,
    },
  },
  {
    path: "*",
    name: "NotFound",
    components: {
      header: Header,
      default: Error404,
      footer: Footer,
    },
  },
  {
    path: "/500",
    name: "Error",
    components: {
      header: Header,
      default: Error500,
      footer: Footer,
    },
  },
  {
    path: "/search/detail/:urn-:country",
    name: "SearchDetail",
    components: {
      header: Header,
      default: SearchDetail,
      footer: Footer,
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    components: {
      header: Header,
      default: TermsAndConditions,
      footer: Footer,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    components: {
      header: Header,
      default: ForgotPassword,
      footer: Footer,
    },
  },
  {
    path: "/verify-email/:token",
    name: "VerifyEmail",
    components: {
      header: Header,
      default: VerifyEmail,
      footer: Footer,
    },
  },
  {
    path: "/live",
    name: "Live",
    components: {
      header: Header,
      default: Live,
      footer: Footer,
    },
  },
 
  {
    path: "/history",
    name: "History",
    components: {
      header: Header,
      default: History,
      footer: Footer,
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    components: {
      header: Header,
      default: ResetPassword,
      footer: Footer,
    },
  },
  {
    path: "/direct-search",
    name: "DirectSearch",
    components: {
      header: Header,
      default: DirectSearch,
      footer: Footer,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
});

router.beforeEach((to, from, next) => {
  const auth = store.getters.auth;
  if ((to.name == "Login" || to.name == "Signup") && auth) {
    next("Account");
  } else if (to.meta.requiresAuth && !auth) {
    next("Login");
  }
  next();
});

export default router;
