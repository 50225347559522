<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition
  },
  mounted() {
    if (localStorage.getItem("access")) {
      this.$axios
        .post("verify/", { token: localStorage.getItem("access") })
        .then(res => {
          if (res) {
            this.$axios.defaults.headers.common = {
              Authorization: "Bearer " + this.$store.getters.access
            };
          }
        })
        .catch(err => {
          if (err.response) {
            this.$store.dispatch("logout");
            delete this.$axios.defaults.headers.common["Authorization"];
          }
        });
    }
  }
};
</script>
<style>
.ntt {
  /*no text transform  */
  text-transform: none !important;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
</style>
